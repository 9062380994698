import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"

import ManagedServicesBanner from "@images/hero-bg3.jpg"

const ManagedServices = () => (
	<Layout>
		<SEO
			title="Managed IT Services Boulder County | Managed IT Services Provider | NeighborhoodIT"
			description="NeighborhoodIT serves as a managed IT services provider who offers enterprise-class technology services to Boulder County small businesses."
		/>
		<div
			id="hero-banner"
			style={{
				backgroundImage: `url(${ManagedServicesBanner})`,
				backgroundPosition: `center`,
				backgroundSize: `cover`,
				backgroundRepeat: `no-repeat`,
			}}
		>
			<div className="hero-text">
				<div className="container">
					<div className="row justify-content-between align-items-center text-white">
						<div className="col-12">
							<h1>
								Is your technology costing you more money than it is making you?
							</h1>
							<p>
								Information technology is supposed to make doing business more
								efficient and more profitable.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="page-content">
			<div className="container">
				<div className="row justify-content-between align-items-center">
					<div className="col-12">
						<h1>Managed IT Services</h1>
						<p>
							For many small and midsize organizations struggling to manage
							their IT internally, technology is often more of a burden than a
							benefit.
						</p>
						<p>
							If you've suffered from a technology related headache or work
							stoppage in the past year, then that's one time too many. Let our
							managed IT services ensure that your business' hardware, software,
							networks and other systems run smoothly and efficiently. Our
							dedicated lineup of engineers and support specialists will
							proactively monitor your essential systems and respond quickly to
							any needs you might have.
						</p>
						<p>
							We provide a wide range of vital IT services on a subscription
							basis. We provide laptop, desktop, server and network management
							services to small and medium sized businesses that seek a more
							beneficial (read: profitable) relationship with technology. If
							that sounds like you, contact us today.
						</p>
						<h3>Focus on growing your business</h3>
						<p>
							Our managed IT services reduce the pressures, annoyances and costs
							associated with trying to manage and support your IT yourself,
							freeing you up to focus on your core business
							objectives-generating leads, growing sales, keeping customers
							happy and tapping new sources of revenue.
						</p>
						<p>
							What's more, with our remote managed services, our clients enjoy
							faster support and better performance from their key systems-and
							they have the peace of mind that comes with having predictable
							(and low) monthly IT costs.
						</p>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default ManagedServices
